
nav = {
    init: function() {
        nav.cE();
    },
    cE: function () {
        nav.openNav   = $("[data-control='menu-open']");
        nav.bottomBar = $('#bottom-bar');
        nav.topBar    = $('#top-bar');
        nav.middleBar = $('#middle-bar');
        //
        nav.eL();
    },
    eL: function () {
        nav.openNav.on("click", nav.toggleNavigation);
    },
    toggleNavigation: function(evt){
        evt.preventDefault();
        if ( document.body.classList.contains("show-navigation") ){
            document.body.classList.remove("show-navigation");
            nav.removeSVGTransform();
        } else {
            document.body.classList.add("show-navigation");
            nav.addSVGTransform();
        }
    },
    addSVGTransform: function(){
        nav.bottomBar.attr('transform', 'translate(-18.109863281 8) rotate(-45 0 0)');
        nav.topBar.attr('transform', 'translate(10.109863281 -1.9455729) rotate(45 0 0)');
        nav.middleBar.attr('style', 'display: none');
    },
    removeSVGTransform: function(){
        nav.bottomBar.attr('transform', '');
        nav.topBar.attr('transform', '');
        nav.middleBar.attr('style', 'display: block');
    }
}
