careers = {
    init: function () {
        careers.cacheElements();
    },
    cacheElements: function () {
        careers.el = document.querySelector(".jobs-map");
        careers.thisMap = "";
        careers.statesWithJobs = [];
        careers.usBounds = "";
        careers.boundNE = "";
        careers.boundSW = "";

        careers.corporateJobs = 0;
        careers.salesRepJobs = 0;
        careers.salesMgmtJobs = 0;

        careers.districtManager = 0;
        careers.regionalManager = 0;

        careers.jobmapHasIdled = false;

        setTimeout(function () {
            careers.initMap();
        }, 2000);
    },
    initMap: function () {
        careers.corporateJobs = 0;
        careers.salesRepJobs = 0;
        careers.salesMgmtJobs = 0;
        /*
         Initialize the map
         */
        careers.thisMap = new google.maps.Map(careers.el, {
            center: { lat: 41.97, lng: -96.63 },
            zoom: 5,
        });
        /*
         Set the north east and south west corners of the map view.
         */
        careers.usBounds = new google.maps.LatLngBounds();
        careers.boundNE = new google.maps.LatLng(49.38, -66.94);
        careers.boundSW = new google.maps.LatLng(25.82, -124.39);
        careers.usBounds.extend(careers.boundNE);
        careers.usBounds.extend(careers.boundSW);
        careers.thisMap.fitBounds(careers.usBounds);

        careers.parseData();
    },
    parseData: function () {
        fetch("/env")
            .then((response) => response.json())
            .then((data) => {
                const endpoint = data.AWS_JOBS_FILE;

                $.ajax({
                    type: "GET",
                    url: endpoint,
                    dataType: "json",
                    crossDomain: true,
                    statusCode: {
                        200: function (data) {
                            var jobs_json = data;

                            var infoWindow = new google.maps.InfoWindow();

                            var oms = new OverlappingMarkerSpiderfier(
                                careers.thisMap,
                                {
                                    markersWontMove: true, // we promise not to move any markers, allowing optimizations
                                    markersWontHide: true, // we promise not to change visibility of any markers, allowing optimizations
                                    keepSpiderfied: true,
                                    nearbyDistance: 10,
                                    circleFootSeparation: 24,
                                    circleStartAngle: 110,
                                    legWeight: 1.75,
                                }
                            );

                            careers.thisMap.addListener("click", function () {
                                infoWindow.close(careers.thisMap);
                            });

                            careers.getLocationInfo(jobs_json);

                            var addToMap = [];

                            jobs_json.features.forEach(function (feature) {
                                var position = new google.maps.LatLng(
                                    feature.geometry.coordinates[1],
                                    feature.geometry.coordinates[0]
                                );
                                var location_name =
                                    feature.properties.location_name;
                                feature.properties.jobs.forEach(function (job) {
                                    jobObj = {};
                                    var icon = careers.getMarkerColor(job);
                                    var regIcon = icon.icon;
                                    var plusIcon = icon.plusIcon;

                                    jobObj.position = position;
                                    jobObj.location_name = location_name;
                                    jobObj.icon = regIcon.url;
                                    jobObj.plusIcon = plusIcon.url;
                                    jobObj.job_type = job.job_type;
                                    jobObj.job_title = job.job_title;
                                    jobObj.job_url = job.job_url;

                                    addToMap.push(jobObj);
                                });
                            });
                            addToMap.forEach(function (feature) {
                                (function () {
                                    var marker = new google.maps.Marker({
                                        position: feature.position,
                                        icon: feature.icon,
                                        title: feature.job_title,
                                        map: careers.thisMap,
                                    });

                                    google.maps.event.addListener(
                                        marker,
                                        "spider_click",
                                        function (e) {
                                            if (feature.location_name) {
                                                var jobs_html =
                                                    "<div class='kowa-map-bubble'><strong>" +
                                                    feature.location_name +
                                                    "</strong><ul><li><a href='" +
                                                    feature.job_url +
                                                    "' title='Learn more about Kowa&apos;s " +
                                                    feature.job_title +
                                                    " position in " +
                                                    feature.location_name +
                                                    "' target='_blank'>" +
                                                    feature.job_title +
                                                    "</a></li></ul></div>";

                                                infoWindow.setContent(
                                                    jobs_html
                                                );
                                                infoWindow.setPosition(
                                                    feature.position
                                                );
                                                infoWindow.setOptions({
                                                    pixelOffset:
                                                        new google.maps.Size(
                                                            0,
                                                            -40
                                                        ),
                                                });
                                                infoWindow.open(
                                                    careers.thisMap
                                                );
                                            } else {
                                                infoWindow.close(
                                                    careers.thisMap
                                                );
                                            }
                                        }
                                    );

                                    google.maps.event.addListener(
                                        marker,
                                        "spider_format",
                                        function (status) {
                                            marker.setIcon({
                                                url:
                                                    status ==
                                                    OverlappingMarkerSpiderfier
                                                        .markerStatus.SPIDERFIED
                                                        ? feature.icon
                                                        : status ==
                                                          OverlappingMarkerSpiderfier
                                                              .markerStatus
                                                              .SPIDERFIABLE
                                                        ? "../../images/map-icons/grey-icon-plus.png"
                                                        : status ==
                                                          OverlappingMarkerSpiderfier
                                                              .markerStatus
                                                              .UNSPIDERFIABLE
                                                        ? feature.icon
                                                        : null,
                                                scaledSize:
                                                    new google.maps.Size(
                                                        27,
                                                        44
                                                    ),
                                            });
                                        }
                                    );
                                    oms.addMarker(marker);
                                })();
                            });
                        },
                    },
                    error: function (error) {
                        console.error(error);
                    },
                });
            });
    },
    getMarkerColor: function (data) {
        var greenIcon = {
            url: "../../images/map-icons/green-map-label.png",
        };
        var blueIcon = {
            url: "../../images/map-icons/blue-map-label.png",
        };
        var greyIcon = {
            url: "../../images/map-icons/grey-map-label.png",
        };
        var greenPlusIcon = {
            url: "../../images/map-icons/green-icon-plus.png",
        };
        var bluePlusIcon = {
            url: "../../images/map-icons/blue-icon-plus.png",
        };
        var greyPlusIcon = {
            url: "../../images/map-icons/grey-icon-plus.png",
        };
        var mapIcon;
        var mapPlusIcon;

        switch (data.job_type) {
            case "sales-rep":
                mapIcon = blueIcon;
                mapPlusIcon = bluePlusIcon;
                break;
            case "corporate":
                mapIcon = greenIcon;
                mapPlusIcon = greenPlusIcon;
                break;
            case "sales-management":
                mapIcon = greyIcon;
                mapPlusIcon = greyPlusIcon;
                break;
            default:
                console.log("error with JSON file, no job_type listed");
        }
        return {
            icon: mapIcon,
            plusIcon: mapPlusIcon,
        };
    },
    getLocationInfo: function (data) {
        var totalLocations = data.features.length;

        if (totalLocations < 1) {
            careers.thereAreNoJobs();
        } else {
            document.body.classList.add("has-jobs");
            document.querySelector(".intro-text").innerHTML =
                "<p>To apply for an open position, click on the appropriate link below and complete an online application. Once you submit your application, it will be forwarded to Human Resources for processing.</p><p>Thank you for considering Kowa Pharmaceuticals America, Inc. in your employment search.</p>";
            careers.clickEvent();

            for (var i = 0; i < totalLocations; i++) {
                var jobsArray = data.features[i].properties.jobs;
                var locationName = data.features[i].properties.location_name;
                var coordinates = data.features[i].geometry.coordinates;

                careers.getJobs(jobsArray, locationName, coordinates);
            }
            careers.checkForCorporateJobs();
            careers.checkForSalesRepJobs();
            careers.checkForSalesMgmtJobs();
        }
    },
    clickEvent: function () {
        var infoWindow = new google.maps.InfoWindow();

        careers.thisMap.data.addListener("click", function (evt) {
            // console.log(evt.feature.getProperty('location_name'));
            // console.log(evt.feature.getProperty('jobs'));
        });
    },
    getJobs: function (job, location) {
        for (var i = 0; i < job.length; i++) {
            var jobType = job[i].job_type;
            var jobTitle = job[i].job_title;
            var job_url = job[i].job_url;
            var job_location = location;

            switch (jobType) {
                case "sales-rep":
                    careers.insertJobHTML(
                        ".sales-rep-listings",
                        job_url,
                        job_location,
                        jobTitle,
                        false
                    );

                    careers.salesRepJobs += 1;
                    break;
                case "sales-management":
                    careers.salesMgmtJobs += 1;

                    if (jobTitle === "Regional Manager") {
                        if (careers.regionalManager === 0) {
                            document.querySelector(
                                ".sales-mgmt-listings"
                            ).innerHTML +=
                                "<li class='list-header'>Regional Manager <ul class='rm'></ul></li>";
                        }

                        careers.insertJobHTML(
                            ".rm",
                            job_url,
                            job_location,
                            jobTitle,
                            false
                        );

                        careers.regionalManager += 1;
                    } else if (jobTitle === "District Manager") {
                        if (careers.districtManager === 0) {
                            document.querySelector(
                                ".sales-mgmt-listings"
                            ).innerHTML +=
                                "<li class='list-header'>District Manager <ul class='dm'></ul></li>";
                        }

                        careers.insertJobHTML(
                            ".dm",
                            job_url,
                            job_location,
                            jobTitle,
                            false
                        );

                        careers.districtManager += 1;
                    }

                    break;
                case "corporate":
                    careers.insertJobHTML(
                        ".corporate-listings",
                        job_url,
                        job_location,
                        jobTitle,
                        true
                    );

                    careers.corporateJobs += 1;
                    break;
                default:
                    console.log(
                        "this job doesnt fall under any category, problem with data"
                    );
                    break;
            }
        }
    },
    insertJobHTML: function (
        querySelector,
        url,
        location,
        title,
        jobTitleIsLink
    ) {
        if (jobTitleIsLink === true) {
            document.querySelector(querySelector).innerHTML +=
                "<li><a href='" +
                url +
                "' title='Learn more about Kowa&apos;s " +
                title +
                " position in " +
                location +
                "' target='_blank'>" +
                title +
                "</a></li>";
        } else {
            document.querySelector(querySelector).innerHTML +=
                "<li><a href='" +
                url +
                "' title='Learn more about Kowa&apos;s " +
                title +
                " position in " +
                location +
                "' target='_blank'>" +
                location +
                "</a></li>";
        }
    },
    thereAreNoJobs: function () {
        document.body.classList.add("no-jobs");
        document.querySelector(".intro-text").innerHTML =
            "<p>Thank you for your interest in Kowa Pharmaceuticals America, Inc. Currently there are no open positions. Please check back again soon.</p>";

        careers.checkForCorporateJobs();
        careers.checkForSalesRepJobs();
        careers.checkForSalesMgmtJobs();
    },
    checkForCorporateJobs: function () {
        if (careers.corporateJobs === 0) {
            document.querySelector(".corporate-listings").innerHTML =
                "<li>Currently no openings</li>";
        }
    },
    checkForSalesRepJobs: function () {
        if (careers.salesRepJobs === 0) {
            document.querySelector(".sales-rep-listings").innerHTML =
                "<li>Currently no openings</li>";
        }
    },
    checkForSalesMgmtJobs: function () {
        if (
            careers.salesMgmtJobs === 0 &&
            careers.districtManager === 0 &&
            careers.regionalManager === 0
        ) {
            document.querySelector(".sales-mgmt-listings").innerHTML =
                "<li>Currently no openings</li>";
        } else if (careers.districtManager === 0) {
            document.querySelector(".sales-mgmt-listings").innerHTML +=
                "<li class='list-header'>District Manager <ul><li>Currently no openings</li></ul></li>";
        } else if (careers.regionalManager === 0) {
            document.querySelector(".sales-mgmt-listings").innerHTML +=
                "<li class='list-header'>Regional Manager <ul><li>Currently no openings</li></ul></li>";
        }
    },
};
