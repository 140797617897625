podcasts = {
    init: function () {
        podcasts.cE();
    },
    cE: function () {
        podcasts.modalToShow = null;
        podcasts.podcastName = '';
        podcasts.eL();
    },
    eL: function () {
        if(document.body.classList.contains('sales-training')){
            podcasts.getSalesTrainingName();
        } else {
            podcasts.getPodcastName();
        }
        if(podcasts.modalToShow){
            $('.overlay').addClass('show');
            $('#podcastName').html(podcasts.podcastName);
            $('#playThis').on('click', function(){
                $('[data-track="'+podcasts.modalToShow+'"')[0].play();
                $('.overlay').removeClass('show');
            });
        }
    },
    getPodcastName: function () {
        podcasts.modalToShow = podcasts.getUrlVars()['a'];
        switch ( podcasts.modalToShow ) {
            case '1':
                podcasts.podcastName = "Intro to LIVALO Drive-Time Podcasts";
                break;
            case '2':
                podcasts.podcastName = "Optymyze for Success";
                break;
            case '3':
                podcasts.podcastName = "Learn How Optymyze Can Elevate Your Sales Success";
                break;
            case '4':
                podcasts.podcastName = "Let LEAD be your guide to increasing scripts";
                break;
            case '5':
                podcasts.podcastName = "Only 20 working days left and everyone is still in the running for Imperial Club!";
                break;
            case '6':
                podcasts.podcastName = "You determine whether or not you walk the stage in New Orleans, so finish strong!";
                break;
            case '7':
                podcasts.podcastName = "Bonus Podcast: The VR Experience is getting some reps 3-10 minutes with their HCPs. Are you one of them?";
                break;
            case '8':
                podcasts.podcastName = "Start 2018 strong by getting ahead of managed care changes and new deductibles that could impact your LIVALO business!";
                break;
            case '9':
                podcasts.podcastName = "What are physicians saying about making LIVALO their go-to 2nd line statin?";
                break;
            case '10':
                podcasts.podcastName = "Listen to proven ways to increase your territory productivity!";
                break;
            case '11':
                podcasts.podcastName = "How did these reps get their previously non-writing HCPs to become top prescribers?";
                break;
            case '12':
                podcasts.podcastName = "What happens when the stars align?";
                break;
            default:
                podcasts.podcastName = "";
                break;
        }
    },
    getSalesTrainingName: function () {
        podcasts.modalToShow = podcasts.getUrlVars()['a'];
        switch ( podcasts.modalToShow ) {
            case '1':
                podcasts.podcastName = "Strategic Probing<br>Identifying the Healthcare Provider's Approach to Treatment";
                break;
            case '2':
                podcasts.podcastName = "Clear, Credible, and Compelling Communication <br> Is your approach in line with clear, credible, and compelling speakers?";
                break;
            case '3':
                podcasts.podcastName = "Inbound Social Intelligence<br>Are You Accurately Reading the Reactions of Healthcare Providers?";
                break;
            case '4':
                podcasts.podcastName = "The A to B Shift<sup>TM</sup><br>How to become experts in change management";
                break;
            case '5':
                podcasts.podcastName = "Gaining Meaningful Commitment<br>How to earn concrete and meaningful commitments from appropriate stakeholders in the practice";
                break;
            case '6':
                podcasts.podcastName = "Becoming a Practice Partner<br>Finding and eliminating friction is key";
                break;
            default:
                podcasts.podcastName = "";
                break;
        }
    },
    getUrlVars: function () {
        var vars = [], hash;
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for ( var i = 0; i < hashes.length; i++ ) {
            hash = hashes[i].split('=');
            vars.push(hash[0]);
            vars[hash[0]] = hash[1];
        }
        return vars;
    }
}
