newsroom = {
    init: function initialize() {
        newsroom.cE();
        newsroom.fireToggle();
    },
    cE : function () {
        newsroom.toggleNewsButtons = $("[data-control='toggle-year']");
        newsroom.eL();
    },
    eL: function () {
        if ( newsroom.toggleNewsButtons && newsroom.toggleNewsButtons.length > 0 ) {
            $.each(newsroom.toggleNewsButtons, function(){
                $(this).on("click", newsroom.toggleNews);
            });
        }
    },
    fireToggle: function(){
        console.log(newsroom.toggleNewsButtons);
        newsroom.toggleNewsButtons[0].click();
    },
    toggleNews: function(evt){
        var currentButton = evt.target,
            currentState = currentButton.getAttribute("data-state"),
            currentList = currentButton.nextElementSibling,
            currentListItems = currentList.querySelectorAll("li");
        switch(currentState){
            case "open":
                for(var i = 0; i < currentListItems.length; i++){
                    Velocity(currentListItems[i], "slideUp", "easeOut");
                }
                currentButton.setAttribute("data-state", "closed");
                break;
            case "closed":
            case "":
                for(var i = 0; i < currentListItems.length; i++){
                    Velocity(currentListItems[i], "slideDown", "easeOut");
                }
                currentButton.setAttribute("data-state", "open");
                break;
            default:
            // do nothing
        }
        evt.preventDefault();
    }
}
