require("./bootstrap");

kowa = {
    init: function () {
        nav.init();
        if (document.body.classList.contains("home")) {
            document.body.classList.add("animate-the-svg");
            window.setTimeout(function () {
                document.body.classList.add("colorize-the-svg");
            }, 3000);
        }
        if (document.body.classList.contains("contact")) {
            contact.init();
        }
        if (document.body.classList.contains("newsroom")) {
            newsroom.init();
        }
        if (document.body.classList.contains("opportunities")) {
            careers.init();
        }
    },
};

kowa.init();
