
contact = {
    init: function(){
        contact.cE();
    },
    cE: function(){
        contact.form = $('form.contact-form');
        contact.submitBtn = $('.submit-btn');
        contact.radioBtns = $('form.contact-form input[type="radio"]');
        contact.dropdown = $('#product');
        contact.livaloAlert = $('.livalo-alert');
        contact.seglentisAlert = $('.seglentis-alert');
        contact.productInfo = false;
        contact.eL();
    },
    eL: function(){
        contact.submitBtn.on('click', function(e){
            e.preventDefault();
            contact.validateForm();
        });
        $.each(contact.radioBtns, function() {
            $(this).on('change', function(event) {
                // Change product dropdown back to select option
                document.getElementById('product').value = "undefined";
                // Remove product information language
                if (contact.livaloAlert.hasClass('show')){
                    contact.livaloAlert.removeClass('show');
                }
                if (contact.seglentisAlert.hasClass('show')){
                    contact.seglentisAlert.removeClass('show');
                }
                // unset product info
                contact.productInfo = false;
                if(event.target.id === "type-healthcare" || event.target.id === "type-consumer") {
                    contact.toggleType(event);
                } else {
                    if (event.target.value === "Product Information/Medical question"){
                        contact.productInfo = true;
                    }
                    contact.toggleProducts(event);
                }
            });
        });
        contact.dropdown.on('change', function(e){
            if(contact.productInfo){
                if (this.value === 'Seglentis'){
                    if (contact.livaloAlert.hasClass('show')){
                        contact.livaloAlert.removeClass('show');
                    }
                    contact.seglentisAlert.addClass('show');
                } else if (this.value === "Livalo"){
                    if (contact.seglentisAlert.hasClass('show')){
                        contact.seglentisAlert.removeClass('show');
                    }
                    contact.livaloAlert.addClass('show')
                } else {
                    if (contact.livaloAlert.hasClass('show')){
                        contact.livaloAlert.removeClass('show');
                    }
                    if (contact.seglentisAlert.hasClass('show')){
                        contact.seglentisAlert.removeClass('show');
                    }
                }
            }
        });
    },
    validateForm: function() {
        let theData       = contact.processFormData(document.querySelectorAll('form.contact-form input, form.contact-form textarea, form.contact-form select')),
            frmData       = theData.dataObj,
            formHasErrors = contact.checkForErrors(frmData);
        if (! formHasErrors) {
            contact.formSubmission(theData.dataObjSubmission);
        }
    },
    printErrors: function(obj) {
        for (key in obj) {
            // console.log("key is", key);
            let
                $currentInput = (key === 'department') ? document.querySelector('form #department-products') : document.querySelector('form [name="' + key + '"]'),
                $errorDiv     = document.createElement('div'),
                $el           = (key === 'department') ? document.querySelector('#department-products') : document.querySelector('[name="' + key + '"]'),
                hasError      = false,
                errorClass    = 'has-error',
                $errorMsg     = '';
            if (obj[key].has_error) {
                hasError = true;
            }
            if (hasError) {
                $errorDiv.setAttribute('id', 'error-' + key);
                for (let i = 0; i < obj[key].error_msg.length; i++) {
                    $errorMsg += '<small>' + obj[key].error_msg[i] + '</small>'
                }
                $errorDiv.innerHTML = $errorMsg;
                contact.getClosest($el, '.field-wrapper').classList.add(errorClass);
                contact.getClosest($el, '.field-wrapper').appendChild($errorDiv);
            }
        }
    },
    checkForErrors: function(obj) {
        console.log('hitting check for errors');
        let formHasErrors = false,
            checkForNull  = function(key) {
                // console.log('hitting check for null');
                if (obj[key].required && ! obj[key].value || (obj[key].required && obj[key].value === '1')) {
                    formHasErrors = true;
                    obj[key].has_error = true;
                    obj[key].error_msg.push('This field is required.');
                }
                // 1 off for the select
                if (key === 'product') {
                    var $radioVal = (document.querySelector('input[name="department"]:checked')) ? (document.querySelector('input[name="department"]:checked').value !== 'Sales/Marketing') ? true : false : null;
                    if ($radioVal) {
                        if (document.querySelector('select#product').value === 'undefined') {
                            formHasErrors = true;
                            obj[key].has_error = true;
                            obj[key].error_msg.push('This field is required.');
                        }
                    }
                }
                // New fields added so we need to make sure that these correctly replicate the required functionality as created when "key==='product'"
                if(key === "designation" || key === "consumer") {
                    let v = document.getElementById(key).value,
                        i = key === "consumer" ? document.getElementById('type-'+key) : document.getElementById('type-healthcare');
                    if(v === "undefined" && i.checked) {
                        formHasErrors = true;
                        obj[key].has_error = true;
                        obj[key].error_msg.push('This field is required.');
                    }
                }
            }, // END checkForNull
            fieldTypeCheck = {
                tel: function() {
                    let
                        regex = /^(\(?[0-9]{3}\)?)((\s|\-){1})?[0-9]{3}((\s|\-){1})?[0-9]{4}$/,
                        val = obj[key].value;
                    if ((! regex.test(val))&& val !== null) {
                        formHasErrors = true;
                        obj[key].has_error = true;
                        obj[key].error_msg.push('Phone number must be 10 numeric characters.');
                    }
                },
                email: function(key) {
                    let
                        regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-zA-Z]{2,6}(?:\.[a-zA-Z]{2})?)$/,
                        val = obj[key].value;
                    if ((! regex.test(val)) && val !== null) {
                        formHasErrors = true;
                        obj[key].has_error = true;
                        obj[key].error_msg.push('This is not a valid email.');
                    }
                },
                comments: function(key){
                    let
                        regex = /[^0-9a-zA-Z@!?;*:()+&\-,.\n\r\t ]/gm,
                        val = obj[key].value;
                    if((regex.test(val)) && val !== null){
                        formHasErrors = true;
                        obj[key].has_error = true;
                        obj[key].error_msg.push('This comment contains characters that are not allowed, including links.  Please remember not to copy and paste the comment, it must be typed out within the text box.  Please try again.');
                        // console.log('ERROR ON COMMENTS');
                    }
                }
            };
        for (key in obj) {
            checkForNull(key);
            switch (key) {
                case 'email':
                    fieldTypeCheck.email(key);
                    break;
                case 'phone':
                    fieldTypeCheck.tel(key);
                    break;
                case 'comments':
                    fieldTypeCheck.comments(key);
                    break;
            }
        }
        contact.resetErrors();
        contact.printErrors(obj);
        return formHasErrors;
    },
    removeElement: function(id) {
        let elem = document.getElementById(id);
        elem.parentNode.removeChild(elem);
    },
    resetErrors: function() {
        let errorContainers = document.querySelectorAll('.has-error');
        [].forEach.call(errorContainers, function(errorContainer) {
            errorContainer.classList.remove('has-error');
            let errorEl = errorContainer.querySelector('div[id^="error-"]');
            let errorElId = errorEl.getAttribute('id');
            contact.removeElement(errorElId);
        });
    },
    resetFormInputs: function() {
        let formInputs = document.querySelectorAll('form.contact-form input, form.contact-form textarea, form.contact-form select');
        [].forEach.call(formInputs, function(formInput) {
            formInput.value = '';
        });
    },
    resetForm: function() {
        contact.resetErrors();
        contact.resetFormInputs();
    },
    processFormData: function(theData) {

        let
            dataObj = {},
            dataObjSubmission = {};

        [].forEach.call(theData, function(data) {

            var val = null;
            if (data.type === 'radio') {
                if (document.querySelector('input[name="' + data.name + '"]:checked')) {
                    var $radios = document.querySelectorAll('input[name="' + data.name + '"]');
                    [].forEach.call($radios, function(radio) {
                        if (radio.checked) {
                            val = radio.value;
                            dataObjSubmission[data.name] = radio.value;
                        }
                    });
                }
            } else if (data.name === 'comments'){
                val = data.value;
                // let currentVal = data.value,
                //     regex      = /[\n\r\t]/gm;
                //
                // val = currentVal.replace(regex, '');
                dataObjSubmission[data.name] = val;
            } else {
                val = data.value;
                dataObjSubmission[data.name] = data.value;
            }
            dataObj[data.name] = {
                value: val,
                type: data.type,
                required: data.getAttribute('data-req'),
                has_error: false,
                error_msg: []
            };
        });
        // add the js timestamp
        dataObjSubmission['current_time'] = Date.now();  // new Date();
        return {dataObj: dataObj, dataObjSubmission: dataObjSubmission};
    },
    toggleProducts: function(evt){
        var products = document.querySelector(".contact-products");
        if(evt.target.value === "Product Information/Medical question" || evt.target.value === "Patient Savings/Patient Assistance Programs" || evt.target.value === "Adverse Event-Product Reaction"){
            Velocity(products, "slideDown");
        } else {
            if(products.closest('.field-wrapper.has-error')){
                products.closest('.has-error.field-wrapper').classList.remove('has-error');
                contact.removeElement('error-product');
            }
            Velocity(products, "slideUp");
            document.querySelector('select#product').value = "undefined";
        }
    },
    formSubmission: function(frmData) {
        $.ajax({
            type: "POST",
            url: '/contact-form/',
            data: frmData,
            dataType: 'json',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') // CSRF for Ajax
            }
        }).done(function(resp){
             console.log('resp is ', resp);
            if (resp === 1) {
                // document.body.scrollTop = 0;
                contact.form.html('<p>Form submitted successfully.</p><p>Thank you for contacting Kowa Pharmaceuticals America, Inc.</p>');
                // console.log('form submission a success');
            } else {
                // document.body.scrollTop = 0;
                 contact.form.innerHTML = '<p>The form submission encountered an issue.</p><p>Please <a href="/contact/">click here</a> to try again.</p>';
                // console.log('The form encountered an error');
            }
            return true;
        }).fail(function(err){
            console.log('The site encountered an issue: ' + err.responseText);
            // Throw comments error
            let commObj = [];
            commObj['comments'] = [];
            commObj['comments']['error_msg'] = ['Please check your message for any non-standard characters.  We cannot deliver rich text, links or copy/pasted messages'];
            commObj['comments']['has_error'] = true;
            commObj['comments']['type'] = "textarea";

            contact.printErrors(commObj);
        });
    },
    toggleType: function(evt){
        var designation = document.querySelector(".designation-selection"),
            consumer = document.querySelector(".consumer-selection");
        if(evt.target.id === "type-healthcare"){
            Velocity(designation, "slideDown");
            Velocity(consumer, "slideUp");
            document.querySelector('select#consumer').value = "undefined";
        } else {
            Velocity(consumer, "slideDown");
            Velocity(designation, "slideUp");
            document.querySelector('select#designation').value = "undefined";
        }
    },
    getClosest: function( elem, selector ) {

        // Variables
        var firstChar = selector.charAt(0);
        var supports = 'classList' in document.documentElement;
        var attribute, value;

        // If selector is a data attribute, split attribute from value
        if ( firstChar === '[' ) {
            selector = selector.substr( 1, selector.length - 2 );
            attribute = selector.split( '=' );

            if ( attribute.length > 1 ) {
                value = true;
                attribute[1] = attribute[1].replace( /"/g, '' ).replace( /'/g, '' );
            }
        }

        // Get closest match
        for ( ; elem && elem !== document && elem.nodeType === 1; elem = elem.parentNode ) {

            // If selector is a class
            if ( firstChar === '.' ) {
                if ( supports ) {
                    if ( elem.classList.contains( selector.substr(1) ) ) {
                        return elem;
                    }
                } else {
                    if ( new RegExp('(^|\\s)' + selector.substr(1) + '(\\s|$)').test( elem.className ) ) {
                        return elem;
                    }
                }
            }

            // If selector is an ID
            if ( firstChar === '#' ) {
                if ( elem.id === selector.substr(1) ) {
                    return elem;
                }
            }

            // If selector is a data attribute
            if ( firstChar === '[' ) {
                if ( elem.hasAttribute( attribute[0] ) ) {
                    if ( value ) {
                        if ( elem.getAttribute( attribute[0] ) === attribute[1] ) {
                            return elem;
                        }
                    } else {
                        return elem;
                    }
                }
            }

            // If selector is a tag
            if ( elem.tagName.toLowerCase() === selector ) {
                return elem;
            }

        }

        return null;

    }
}
